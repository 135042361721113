$fa-font-path: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts";
@import "/node_modules/font-awesome/scss/font-awesome.scss";

@import "./assets/themes/ds-theme/index.scss";

// START FIX https://github.com/valor-software/ngx-bootstrap/issues/6490
// TLDR: ngx-bootstrap breaks tooltips and popovers on update from bootstrap 5.1 to 5.2 
.popover {
    position: absolute;
    top: 0;
    left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
    position: absolute;
}

.tooltip {
    position: absolute;
}

.tooltip-arrow {
    position: absolute;
}
// END FIX https://github.com/valor-software/ngx-bootstrap/issues/6490