.navbar {
    border-bottom: 1px solid $medium-gray;

    &-light .navbar-nav .nav-link,
    &-light .navbar-nav .nav-link {

        &:hover,
        &:focus {
            background: $brand-secondary-glow;
        }
    }

    .nav-item.active {

        .nav-link {
            font-weight: bold;

            &:hover,
            &:focus,
            &:active {
                color: $white;
            }
        }
    }
}

.nav-item.separated {

    a {
        display: inline-block;
    }

    &::before {
        content: '|';
        display: inline-block;
        color: $light-gray;
        padding: 0 0.5rem;
    }
}

// .navbar:not(.navbar-expand) > .container-fluid > .navbar-collapse {
@media (max-width: 991px) {
    .navbar.navbar-expand-lg > .container-fluid > .navbar-collapse {

        .nav-link {
            padding: 0.5rem;
        }

        .nav-item.separated {
            // padding-top: 0.5rem;
            // margin-top: 0.5rem;
            border-top: 1px solid $light-gray;

            a {
                display: block;
            }

            &::before {
                content: none;
            }
        }
    }
}

.dropdown-item.nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
