form {

    label {
        font-size: calc($font-size-base * 14 / 16);
    }

    .input-group > input + div > button {
        padding: 0.655rem;
        border-radius: 0;
        margin-left: -5px;
    }

    input,
    select,
    textarea {

        &.ng-invalid.ng-touched {
            border-color: $danger;
            background-color: lighten($danger, 45);
        }
    }

    .form-check .form-check-input {
        display: none;

        & + label.form-check-label {

            &::before {
                content: '';
                text-align: center;
                width: 1.5em;
                height: 1.5em;
                display: inline-block;
                margin-left: -1.25rem;
                margin-right: 0.25rem;
                margin-bottom: -0.375em;
                border: 1px solid $light-gray;
            }
        }

        &.checked + label.form-check-label {

            &::before {
                content: $fa-var-check;
                font-family: FontAwesome;
                color: $white;
                background: $primary;
                border-color: $brand-secondary-glow;
            }
        }

        &:disabled + label.form-check-label {

            &::before {
                background-color: $light-gray;
                border-color: $medium-gray;
            }
        }
    }
}
