.alert {

    &-danger,
    &-success {
        color: $white;
        text-shadow: none;
    }

    &-info {
        background-color: $light-gray;
    }

    &-dismissible {

        &.alert-danger,
        &.alert-success {

            .btn-close {
                color: $white;
                text-shadow: none;
                opacity: 0.75;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
