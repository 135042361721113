@font-face {
    font-family: 'DINWeb';
    src: url('fonts/DINWeb.woff') format('woff');
}

@font-face {
    font-family: 'DINWeb-Medium';
    src: url('fonts/DINWeb-Medium.woff') format('woff');
}

@font-face {
    font-family: 'DIN Next W02 Regular';
    src: url('fonts/DINNext-Regular.woff') format('woff');
}
