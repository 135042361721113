.btn {
    padding-top: calc(#{$btn-padding-y} - 0.0675em);
    padding-bottom: calc(#{$btn-padding-y} + 0.0675em);

    &-sm {
        padding-top: calc(#{$input-btn-padding-y-sm} - 0.05em);
        padding-bottom: calc(#{$input-btn-padding-y-sm} + 0.05em);
    }

    &-primary,
    &-secondary {
        color: $white;

        &:disabled {
            background-color: $medium-gray;
            border-color: $medium-gray;
        }

        &:hover,
        &:active,
        &:focus {
            color: $white;
        }
    }

    &-light {
        color: $body-color;

        &:hover,
        &:active,
        &:focus {
            color: darken($body-color, 15);
        }
    }

    &-outline {
        color: $link-color;
        background-color: $white;
        border: 1px solid $link-color;

        &:hover,
        &:active,
        &:focus {
            color: $link-hover-color;
            border-color: $link-hover-color;
        }
    }

    &-link {

        &:hover {
            text-decoration: none;
        }
    }
}
