#dsNavItemLinkHomeOne {
    display: inline-block;
    padding: 0.17rem 0.5rem;
}

a:hover {
    text-decoration: none;
}

.container-fluid {
    max-width: 1320px;
}

.force-wrap {
    white-space: normal;
    word-break: break-all; 
}

.control-message {
    color: $danger;
    font-weight: bold;
}

.limited-text-width {
    max-width: 60rem;
}

.ms--4 {
    margin-left: -1.5rem;
}

.no-border-radius {
    border-radius: 0;
}

hr.subtle {
    border-color: $light-gray;
    border-style: dashed;
}

.block {
    display: block;
}

.lh-125 {
    line-height: 1.25;
}

.text-secondary {
    color: lighten($body-color, 7%);
}

.btn.social-media {
    color: #000;
    border: $primary 1px solid;
}

.social-media:hover {
    color: #000 !important;
    border: $primary 1px solid !important;
    box-shadow: 0 0 0 1px $primary;
}

.facebook {
    padding: 7px 0.5rem;
    svg {
        color: #4267B2;
    }
}

.google {
    padding: 7px 0.5rem;
}

.apple {
    padding: 12px 0.75rem;

    i {
        transform: scale(1.70);
        margin-right: 0.125rem;
    }
}

.btn:not(.btn-outline, .btn-link) {
    --bs-btn-hover-color: #{$white};
    --bs-btn-active-color: #{$white};
}

.btn.btn-outline {
    --bs-btn-hover-bg: #{$primary}; 
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-active-bg:  #{$primary}; 
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-active-color: #{$white};
}

.table > :not(caption) > * > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
}